@tailwind base;
@tailwind components;
@tailwind utilities;

.react-datepicker-wrapper {
    width: 100%;
}

.leaflet-container {
    width: inherit;
    height: inherit;
}

.leaflet-routing-container {
    overflow: hidden;
    display: none;
}

.leaflet-popup-tip-container {
    display: none;
}

.leaflet-popup {
    margin-bottom: 16px;
}

.leaflet-popup-content {
    text-align: inherit;
    color: inherit;
    margin: 0;
    width: 2rem !important;
}

.leaflet-popup-content-wrapper {
    background-color: inherit;
    color: inherit;
    box-shadow: inherit;
}

.leaflet-popup::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 40%;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #fff;
}

.leaflet-popup:hover::after {
    border-top-color: inherit;
}
.ant-table-cell {
    padding: 5px !important;
}

.bg-livraison {
    background-color: rgb(200 11 33);
}
